<template>
  <div>
    <div v-if="!projects" class="loading"></div>
    <div v-else>
      <b-container>
        <Heading type="h1" :title="trans('global.projects')" />
        <b-row>
          <b-col v-for="project in projects" :key="project._id" sm="12">
            <b-card class="margin-top-16 vary-list-card">
              <b-row>
                <b-col class="col-sm-auto">
                  <b-img v-if="project.image" :src=" cdnUrl + '/' + project.image[Object.keys(project.image)[Object.keys(project.image).length - 1]].path + '/' + project.image[Object.keys(project.image)[Object.keys(project.image).length - 1]].file + '?fit=crop&w=81&h=54&mask=corners&corner-radius=5,5,5,5'"></b-img>
                  <div v-else style="background-color: green; opacity: 0.2; width: 81px; height: 54px; border-radius: 4px;"></div>
                </b-col>
                <b-col>
                  <b-link class="font-size-large" :to="'/projects/' + project.slug">
                    {{ project.name }}
                  </b-link>
                </b-col>
                <b-col>
                  <p>{{ project.description | truncate(100) }}</p>
                </b-col>
                <b-col cols="1">
                  <b-btn
                    variant="danger"
                    @click="deleteProject(project._id)"
                  >
                    {{ trans('global.delete') }}
                  </b-btn>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Heading from '../../components/Core/Heading'

  const fb = require('../../firebaseConfig')

  export default {
    name: 'AllProjects',
    components: {
      Heading
    },
    data () {
      return {
        cdnUrl: process.env.VUE_APP_IMAGE_CDN_URL
      }
    },
    computed: {
      ...mapState({
        projects: state => state.projects.projects,
        prospects: state => state.prospects.prospects,
        units: state => state.units.units
      })
    },
    beforeMount () {
      this.updateBreadcrumb([{ text: this.trans('global.projects'), to: '/projects' }, { text: this.trans('navigation.all_projects'), to: '#' }])
    },
    methods: {
      deleteProject (projectId) {
        // const self = this
        // const projectData = this.projects[projectId]

        console.log('projectData: ', this.projects)

        const project = this.projects.find(p => p._id === projectId)

        console.log('Project:', project)

        if (project) {
          let modalMessage = 'Sikker på at du vil slette ' + project.name

          const prospects = this.prospects.filter(prospect => prospect.projectId === projectId)

          if (prospects.length > 0) {
            const count = prospects.length === 1 ? 'ett prospekt' : prospects.length + ' prospekter'
            modalMessage += ' og ' + count + ' som hører til dette prosjektet?'
          } else {
            modalMessage += '?'
          }

          console.log('Prospects:', prospects)

          const units = this.units.filter(unit => unit.projectId === projectId)

          this.$bvModal.msgBoxConfirm(modalMessage, {
            title: 'Bekreft sletting av ' + project.name,
            size: 'md',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: this.trans('global.delete'),
            cancelTitle: this.trans('global.cancel')
          }).then((value) => {
            if (value !== false) {
              console.log('Proceed....')

              this.deleteProspectsAndUnits(prospects, units).then(() => {
                fb.projectsCollection.doc(projectId).update({
                  deleted: true
                }).then(() => {
                  this.$bvToast.toast(project.name + ' og ' + prospects.length + ' prospekt(er) ble slettet', {
                    title: project.name + ' ble slettet',
                    variant: 'success',
                    solid: true
                  })
                }).catch((err) => {
                  console.log('Error while deleting the project', err)
                })
              })
            } else {
              console.log('Save was aborted')
            }
          }).catch((err) => {
            console.log('Error')
            console.log(err)
          })
        } else {
          console.log('Nope')
        }
      },
      async deleteProspectsAndUnits (prospects, units) {
        let deletedProspects = 0
        for (const prospectIndex in prospects) {
          console.log('Prospect:', prospects[prospectIndex])
          await fb.prospectsCollection.doc(prospects[prospectIndex]._id).update({
            deleted: true
          }).then(() => {
            deletedProspects++
          }).catch(() => {
            console.log('Error while deleting prospect')
          })
        }

        for (const unitIndex in units) {
          console.log('Unit:', units[unitIndex])
          await fb.unitsCollection.doc(units[unitIndex]._id).update({
            deleted: true
          }).then(() => {
            console.log('Deleted the unit: ' + units[unitIndex]._id)
          }).catch((err) => {
            console.log('Error while deleting unit...', err)
          })
        }

        return deletedProspects
      }
    }
  }
</script>
